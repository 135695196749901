@use "sass:meta" as ---jtf2qfwxpjr;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import './variables.scss';

.dl-horizontal dt {
  font-weight: bold;
  padding: $paddingBase;
  border-bottom: 2px solid $euiColorLightestShade;

  &:last-of-type {
    border-bottom: none;
  }
}

.dl-horizontal dd {
  margin: 0;
  padding: $paddingBase;
  border-bottom: 2px solid $euiColorLightestShade;

  &:last-of-type {
    border-bottom: none;
  }
}

.no-decoration {
  padding-left: 0;
  margin-bottom: 0;

  > li {
    list-style-type: none;
  }
}

;@include ---jtf2qfwxpjr.load-css("sass-embedded-legacy-load-done:347");